import { majorScale } from 'evergreen-ui'

const customTheme = {
    components: {
        Heading: {
            baseStyle: {
                paddingY: majorScale(1)
            }
        },
        Paragraph: {
            baseStyle: {
                paddingY: majorScale(1)
            }
        }

    }
}

export default customTheme
