import React from 'react'
import { useState, useEffect, useRef } from 'react';
import { Pane, Checkbox, Heading, Tablist, Tab, majorScale, ExportIcon } from 'evergreen-ui'
import { fetchGraphData, Element, fetchElementBySlug } from '../api'
import ElementSheet from '../components/ElementSheet'
import CytoscapeComponent from 'react-cytoscapejs'
import { stylesheet } from '../graphoptions'
import { saveAs } from 'file-saver';

export default () => {
    const allParts = ["1", "2", "3", "3DA", "4", "5"]

    const [graphData, setGraphData] = useState()
    const [selectedElementSlug, setSelectedElementSlug] = useState("")
    const [selectedElement, setSelectedElement] = useState<Element | null>(null)
    const [layout, setLayout] = useState("grid-by-part")
    const [filteredParts, setFilteredParts] = useState(["1", "2", "3", "3DA", "4", "5"])
    let cyRef = useRef<cytoscape.Core>()

    useEffect(() => {
        const f = async () => {
            const d = await fetchGraphData()
            setGraphData(d)
        }
        f()
    }, [])

    useEffect(() => {
        const cy = cyRef.current
        allParts.map(p => {
            if (filteredParts.includes(p)) {
                //@ts-ignore
                cy?.elements(`node[book_number = "${p}"]`).show()
            } else {
                //@ts-ignore
                cy?.elements(`node[book_number = "${p}"]`).hide()
            }
        })

    }, [filteredParts])

    useEffect(() => {
        if (selectedElementSlug) {
            const f = async () => {
                const d = await fetchElementBySlug(selectedElementSlug)
                setSelectedElement(d)
            }
            f()
        }
    }, [selectedElementSlug])

    const handleFilterChange = (filter: string) => {
        const newFilteredParts = [...filteredParts]
        const index = filteredParts.indexOf(filter)
        if (index === -1) {
            newFilteredParts.push(filter)
        } else {
            newFilteredParts.splice(index, 1)
        }

        setFilteredParts(newFilteredParts)
    }

    const handleExportImage = () => {
        const cy = cyRef.current
        if (!cy) return
        saveAs(cy.png({ output: "blob", bg: "white"}), "graph.png")
    }

    useEffect(() => {
        const cy = cyRef.current
        if (layout === "grid-by-part" || layout === "circle-by-part") {
            filteredParts.map((i, index) => {
                var sectionSize = layout.includes("circle") ? 1000 : 1250;
                var radius = 6 * (layout.includes('circle') ? (sectionSize / 2) : (sectionSize / 4));
                var step = (2 * Math.PI) / 6;
                var angle = -40;

                const book = cy?.elements(`node[book_number = "${i}"]`)
                book?.layout({
                    name: layout.includes("circle") ? "circle" : "grid",
                    //@ts-ignore
                    animate: false,
                    animationDuration: 500,
                    avoidOverlap: true,
                    animationEasing: 'ease-in-out-sine',
                    boundingBox: {
                        x1: Math.round(radius * Math.cos(angle + step * index) - sectionSize / 2),
                        y1: Math.round(radius * Math.sin(angle + step * index) - sectionSize / 2),
                        w: sectionSize,
                        h: sectionSize,
                    }

                }).run()

            })

            cy?.zoom(layout.includes("circle") ? .08 : .13)
            cy?.pan({ x: 300, y: 300 })

        } else if (layout === "circle") {
            cy?.layout({ name: "circle", fit: true }).run()
        } else if (layout === "concentric-by-part") {
            const concentricOrder = {
                "1": 1,
                "2": 2,
                "3": 3,
                "3DA": 4,
                "4": 5,
                "5": 6
            }
            cy?.layout({
                name: "concentric",
                fit: true,
                levelWidth: (n) => 1,
                equidistant: true,
                concentric: (n) => {
                    //@ts-ignore
                    return concentricOrder[n.data("book_number")]
                }
            }).run()
        }

    }, [layout, filteredParts])

    const setupGraph = (cy: cytoscape.Core) => {
        cyRef.current = cy

        var sectionSize = 1250;
        var radius = 6 * (sectionSize / 4);
        var step = (2 * Math.PI) / 6;
        var angle = -40;

        ['1', '2', '3', '3DA', '4', '5'].map((i, index) => {
            const book = cy.elements(`node[book_number = "${i}"]`)
            book.layout({
                name: "grid",
                animate: false,
                animationDuration: 500,
                avoidOverlap: true,
                animationEasing: 'ease-in-out-sine',
                boundingBox: {
                    x1: Math.round(radius * Math.cos(angle + step * index) - sectionSize / 2),
                    y1: Math.round(radius * Math.sin(angle + step * index) - sectionSize / 2),
                    w: sectionSize,
                    h: sectionSize,
                }

            }).run()

        })

        cy.on('mouseover', 'node', function(e: cytoscape.EventObject) {
            var sel = e.target;

            var outgoers = sel.outgoers();
            var incomers = sel.incomers();

            var all = outgoers.union(incomers);

            cy.elements().difference(all).not(sel).addClass('faded_hover');
            sel.addClass('highlight_hover');
            all.addClass('highlight_hover');
        });

        cy.on('mouseout', 'node', function(e) {
            var sel = e.target;

            var outgoers = sel.outgoers();
            var incomers = sel.incomers();

            var all = outgoers.union(incomers);

            cy.elements().removeClass('faded_hover');
            sel.removeClass('highlight_hover');
            all.removeClass('highlight_hover');
        });

        cy.on('dbltap', 'node', function(e) {
            //@ts-ignore
            setSelectedElementSlug(this.id())
        })

        cy.on('tap', 'node', function(e) {
            const sel = e.target
            var outgoers = sel.outgoers();
            var incomers = sel.incomers();

            var all = outgoers.union(incomers);

            sel.addClass('highlight_click');
            all.addClass('highlight_click');
        });

        cy.on('tap', function(e) {
            var canvas = e.target;
            if (canvas === cy) {
                cy.elements().removeClass('highlight_click');
            }
        });

        cy.on('zoom', function(evt) {
            var highlightedHover = cy.$('.highlight_hover');
            highlightedHover.removeClass('highlight_hover');
            highlightedHover.addClass('highlight_hover');

            var highlightedClick = cy.$('.highlight_click');
            highlightedClick.removeClass('highlight_click');
            highlightedClick.addClass('highlight_click');
        });


        cy.zoom(.13)
        cy.pan({ x: 300, y: 300 })
    }

    return (
        <Pane height="100%" minWidth={"800px"}>
            <Pane display="flex">
                <Pane width={"200px"} marginRight={majorScale(1)} padding={majorScale(1)} background="white">
                    <Pane>
                        <Heading>Filters</Heading>
                        <Checkbox onChange={() => handleFilterChange("1")} checked={filteredParts.includes("1")} label="Part 1" />
                        <Checkbox onChange={() => handleFilterChange("2")} checked={filteredParts.includes("2")} label="Part 2" />
                        <Checkbox onChange={() => handleFilterChange("3")} checked={filteredParts.includes("3")} label="Part 3" />
                        <Checkbox onChange={() => handleFilterChange("3DA")} checked={filteredParts.includes("3DA")} label="Definition of the Affects" />
                        <Checkbox onChange={() => handleFilterChange("4")} checked={filteredParts.includes("4")} label="Part 4" />
                        <Checkbox onChange={() => handleFilterChange("5")} checked={filteredParts.includes("5")} label="Part 5" />
                    </Pane>
                    <Pane>
                        <Heading>Layout</Heading>
                        <Tablist marginTop={majorScale(2)}>
                            <Tab
                                direction="vertical"
                                isSelected={layout === "grid-by-part"}
                                onClick={() => setLayout("grid-by-part")}
                            >
                                Grid by Part
                            </Tab>
                            <Tab
                                direction="vertical"
                                isSelected={layout === "circle-by-part"}
                                onClick={() => setLayout("circle-by-part")}
                            >
                                Circle by Part
                            </Tab>
                            <Tab
                                direction="vertical"
                                isSelected={layout === "concentric-by-part"}
                                onClick={() => setLayout("concentric-by-part")}
                            >
                                Concentric by Part
                            </Tab>
                            <Tab
                                direction="vertical"
                                isSelected={layout === "circle"}
                                onClick={() => setLayout("circle")}
                            >
                                Circle
                            </Tab>
                        </Tablist>
			            <Heading>Actions</Heading>
                        <Tablist>
                            <Tab onClick={handleExportImage}>
                                <ExportIcon marginRight={majorScale(1)} /> Export Image
                            </Tab>
                        </Tablist>

                    </Pane>
                </Pane>
                {
                    graphData &&
                    <CytoscapeComponent
                        cy={setupGraph}
                        stylesheet={stylesheet}
                        elements={CytoscapeComponent.normalizeElements(graphData)}
                        layout={{ name: 'grid' }}
                        minZoom={0.06}
                        maxZoom={1}
                        style={{ width: '100%', height: '800px' }}
                    />
                }
            </Pane>
            <ElementSheet
                element={selectedElement}
                onCloseComplete={() => setSelectedElementSlug("")}
                isShown={selectedElement !== null}
            />
        </Pane>
    )
}
