import { useState, useEffect } from 'react'
import { Pane, majorScale, Text, Heading, TextInput } from 'evergreen-ui'
import SpinozaJPG from '../assets/spinoza.jpg'
import { useNavigate, useLocation } from 'react-router-dom'
import { fetchSlugs } from '../api'
import './Nav.css'

export default () => {
    const navigate = useNavigate()
    const location = useLocation()
    const path = location.pathname

    const [slugs, setSlugs] = useState<string[]>([])
    const [searchText, setSearchText] = useState("")

    useEffect(() => {
        const f = async () => {
            const { slugs } = await fetchSlugs()
            setSlugs(slugs)
        }
        f()
    }, [])

    const handleNavigationChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        navigate(`/element/${e.target.value}`)
    }

    return (
        <Pane className="nav" width="100%">
            <Pane display="flex">
                <img className="navimg" width={80} src={SpinozaJPG} />
                <Pane flex={1} marginLeft={majorScale(1)}>
                    <Heading size={800}>Mapping Spinoza's Ethics</Heading>
                    <Pane display="flex" borderTop>
                        <Pane onClick={() => { navigate("/") }} className={`nav-button clickable ${path === '/' && 'active'}`}>
                            <Text>Home</Text>
                        </Pane>
                        <Pane onClick={() => { navigate("/instructions") }} className={`nav-button clickable ${path.startsWith('/instructions') && 'active'}`}>
                            <Text>Instructions</Text>
                        </Pane>
                        <Pane onClick={() => { navigate("/element") }} className={`nav-button clickable ${path.startsWith('/element') && 'active'}`}>
                            <Text>Text and Tree View</Text>
                        </Pane>
                        <Pane onClick={() => { navigate("/graph") }} className={`nav-button clickable ${path === '/graph' && 'active'}`}>
                            <Text>Graph Visualizations</Text>
                        </Pane>
                        <Pane onClick={() => { navigate("/gpt") }} className={`nav-button clickable ${path === '/graph' && 'active'}`}>
                            <Text>GPT</Text>
                        </Pane>
                    </Pane>
                </Pane>

                <Pane display="flex" flexDirection="column" justifyContent="center" marginLeft={majorScale(1)} width={200}>
                    <select onChange={handleNavigationChange}>
                        <option value={""}>quicknav</option>
                        {slugs.map(s => <option key={`quicknav-${s}`} value={s}>{s}</option>)}
                    </select>
                    <Pane flex={1}>
                        <TextInput
                            margin={0}
                            width="100%"
                            placeholder="Search"
                            value={searchText}
                            onKeyPress={(e: React.KeyboardEvent) => { if (e.key === 'Enter') { navigate(`/search?q=${searchText}`) } }}

                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchText(e.target.value)}
                        />
                    </Pane>
                </Pane>
            </Pane>

        </Pane>
    )
}
