import './App.scss';
import TextPage from './pages/TextPage'
import { ThemeProvider, defaultTheme, mergeTheme, Pane, majorScale } from 'evergreen-ui'
import HomePage from './pages/HomePage'
import ElementPage from './pages/ElementPage'
import GraphPage from './pages/GraphPage'
import InstructionsPage from './pages/InstructionsPage'
import SearchPage from './pages/SearchPage'
import Nav from './components/Nav'
import MapPage from './pages/MapPage'
import GPTPage from './pages/GPTPage'
import {
    HashRouter as Router,
    Routes,
    Route,
} from 'react-router-dom'
import customTheme from './customTheme'

function App() {

    return (
        <Router>
            <ThemeProvider value={mergeTheme(defaultTheme, customTheme)}>
                <Nav />
                <Pane padding={majorScale(2)}>
                    <Routes>
                        <Route path="/" element={<HomePage />} />
                        <Route path="/instructions" element={<InstructionsPage />} />
                        <Route path="/search" element={<SearchPage />} />
                        <Route path="/element" element={<TextPage />} />
                        <Route path="/element/:slug" element={<ElementPage />} />
                        <Route path="/graph" element={<GraphPage />} />
                        <Route path="/map" element={<MapPage />} />
                        <Route path="/gpt" element={<GPTPage />} />
                    </Routes>
                </Pane>
            </ThemeProvider>
        </Router>
    );
}

export default App;
