import { Pane, Heading, Text, majorScale } from 'evergreen-ui'
import { Element } from '../../api'


interface MapListItemProps {
    element: Element
}
export default ({ element }: MapListItemProps) => {
    return (
        <Pane display="flex" padding={majorScale(1)} alignItems="center" background="white" margin={majorScale(1)}>
            <Pane width={100}>
                <Heading>{element.slug}</Heading>
            </Pane>
            <Pane flex={1}>
                <Text>{element.english}</Text>
            </Pane>
        </Pane>
    )
}
