import { Pane, Paragraph, Heading } from 'evergreen-ui'
import PageTile from '../components/HomePage/PageTile'
import GraphImage from '../assets/graph-preview.png'
import ListImage from '../assets/list-preview.png'

export default () => {
    return (
        <Pane>
            <Pane display="flex">
                <Pane flex={1}>
                    <Heading size={600}>Digitizing Spinoza's Ethics</Heading>
                    <Paragraph>
                        This web site offers different ways of visualizing the argumentative structure of the Ethics.

                        <br /><br />

                        The project was funded by a Boston College Exploratory Technology Grant and a Boston College Academic Technology Innovation Grant.

                        <br /><br />

                        <b>Project coordinator:</b> John Bagby, Assistant Professor at The California Institute <br />
                        <b>Engineering:</b> Calvin Morooney, Ben Shippee, and Robert McQueen<br />
                        <b>Academic supervision:</b> Jean-Luc Solère, Phd

                        <br /><br />

                        <b>Sources:</b> The Latin text is from Carl Gebhardt's edition, <i>Spinoza Opera</i>, vol. II (Heidelberg: Carl Winter, 1925). The English translation is by R.H.M. Elwes (1883)

                        <br /><br />

                        For inquiries and comments, please write to: solere [at] bc.edu
                    </Paragraph>
                </Pane>
                <Pane width={400} display="flex">
                    <PageTile title="List View" image={ListImage} link={"/element"} />
                    <PageTile title="Graph View" image={GraphImage} link={"/graph"} />
                </Pane>
            </Pane>


        </Pane >
    )
}
