import { Pane } from 'evergreen-ui'
import { useEffect, useState } from 'react'
import ElementSheet from '../components/ElementSheet'
import { fetchElementBySlug, Element } from '../api'

interface ElementTokenProps {
    slug: string
    value: string
}

const ElementToken = ({ slug, value }: ElementTokenProps) => {
    const [element, setElement] = useState<Element | null>(null)
    const [isShown, setIsShown] = useState(false)

    useEffect(() => {
        const f = async () => {
            const e = await fetchElementBySlug(slug)
            setElement(e)
        }
        f()
    }, [])

    return (
        <Pane display="inline">
            <ElementSheet
                element={element}
                onCloseComplete={() => setIsShown(false)}
                isShown={isShown}
            />
            <a className="clickable elementToken" onClick={() => setIsShown(true)}>{value}</a>
        </Pane>
    )
}

export default ElementToken
