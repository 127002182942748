export function colorForBook(book_num: string) {
    switch (book_num) {
        case '1':
            return "#FA7070";
        case '2':
            return "#F08A5D";
        case '3':
            return "#B83B5E";
        case '4':
            return "#6A2C70";
        case '5':
            return "#293462";
        case '3DA':
            return "#ADE792";
        default:
            console.log("ERR: " + book_num)
            return "#000000";
    }
}

export const elementStylesheet = [
    {
        selector: 'node',
        style: {
            'width': '30px',
            'height': '30px',
            'content': 'data(label)',
            'font-size': 16,
            'background-color': function(ele: cytoscape.NodeSingular) {
                if (ele.hasClass("treeViewHighlighted")) {
                    return "#AAAAAA"
                } else {
                    return colorForBook(ele.data("book_number"));
                }
            },

        }
    },
    {
        selector: 'edge',
        style: {
            'width': 3,
            'curve-style': 'bezier',
            'target-arrow-shape': 'triangle'
        }
    }
]

export const stylesheet = [
    {
        selector: 'node',
        style: {
            'width': '50px',
            'height': '50px',
            'content': 'data(id)',
            'background-color': 'data(color)',
            'font-size': 30
        }
    },
    {
        selector: 'node.highlight',
        style: {
            'width': '200px',
            'height': '200px',
            'font-size': 500
        }
    },
    {
        selector: 'node.faded',
        style: {
            'opacity': 0.4
        }
    },
    {
        selector: 'edge',
        style: {
            'target-arrow-color': '#ccc',
            'target-arrow-shape': 'arrow'
        }
    },
    {
        selector: 'edge.highlight',
        style: {
            'width': '45px',
        }
    },
    {
        selector: 'edge.faded',
        style: {
            'opacity': 0.4
        }
    },
    {
        selector: 'node',
        style: {
            'background-color': function(ele: cytoscape.NodeSingular) {
                return colorForBook(ele.data("book_number"));
            },
            'content': 'data(label)'
        }
    },
    {
        selector: 'node.highlight_hover',
        style: {
            'width': function(ele: cytoscape.NodeSingular) {
                var width = ele.renderedWidth();
                var inverse = 1 / width;
                var n = width + inverse * 500;

                return n;
            },
            'height': function(ele: cytoscape.NodeSingular) {
                var height = ele.renderedHeight();
                var inverse = 1 / height;
                var n = height + inverse * 500;

                return n;
            },
            'font-size': function(ele: cytoscape.NodeSingular) {
                var width = ele.width();
                var inverse = 1 / width;
                var n = width + inverse * 20;

                return n;
            }
        },
    },
    {
        selector: 'node.highlight_click',
        style: {
            'width': function(ele: cytoscape.NodeSingular) {
                var width = ele.renderedWidth();
                var inverse = 1 / width;
                var n = width + inverse * 500;

                return n;
            },
            'height': function(ele: cytoscape.NodeSingular) {
                var height = ele.renderedHeight();
                var inverse = 1 / height;
                var n = height + inverse * 500;

                return n;
            },
            'font-size': function(ele: cytoscape.NodeSingular) {
                var width = ele.width();
                var inverse = 1 / width;
                var n = width + inverse * 20;

                return n;
            }
        },
    },
    {
        selector: 'node.faded_hover',
        style: {
            'opacity': 0.4
        }
    },
    {
        selector: 'node.faded_click',
        style: {
            'opacity': 0.4
        }
    },
    {
        selector: 'edge.highlight_hover',
        style: {
            'width': '20%',
            'font-size': function(edge: cytoscape.EdgeSingular) {
                var width = edge.source().width();
                var inverse = 1 / width;
                var n = width + inverse * 20;

                return n;
            },
            'line-color': function(edge: cytoscape.EdgeSingular) {
                var destination = edge.target();
                return colorForBook(destination.data("book_number"));
            }
        }
    },
    {
        selector: 'edge.faded_hover',
        style: {
            'opacity': 0.4
        }
    },
    {
        selector: 'edge.highlight_click',
        style: {
            'width': '20%',
            'font-size': function(edge: cytoscape.EdgeSingular) {
                var width = edge.source().width();
                var inverse = 1 / width;
                var n = width + inverse * 20;

                return n;
            },
            'line-color': function(edge: cytoscape.EdgeSingular) {
                var destination = edge.target();
                return colorForBook(destination.data("book_number"));
            }
        }
    },
    {
        selector: 'edge.faded_click',
        style: {
            'opacity': 0.4
        }
    }
]
