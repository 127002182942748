import { Element } from "./api"

export function getRandomLoadingMessage() {
    const messages = [
        "Loading by necessity...",
        "Configuring modes...",
        "Reconceiving the natural laws...",
        "Preparing adequate causes..."
    ]

    return messages[Math.floor(Math.random() * messages.length)]
}

export const int2roman = (original: number): string => {
    if (original < 1 || original > 3999) {
        throw new Error('Error: Input integer limited to 1 through 3,999');
    }

    const numerals = [
        ['I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX'], // 1-9
        ['X', 'XX', 'XXX', 'XL', 'L', 'LX', 'LXX', 'LXXX', 'XC'], // 10-90
        ['C', 'CC', 'CCC', 'CD', 'D', 'DC', 'DCC', 'DCCC', 'CM'], // 100-900
        ['M', 'MM', 'MMM'], // 1000-3000
    ];

    // TODO: Could expand to support fractions, simply rounding for now
    const digits = Math.round(original).toString().split('');
    let position = (digits.length - 1);

    return digits.reduce((roman, digit) => {
        if (digit !== '0') {
            roman += numerals[position][parseInt(digit) - 1];
        }

        position -= 1;

        return roman;
    }, '');
}

export const getAllDependsOnForParent = (parent: Element) => {
    const depends_on: Element[] = []
    const seenSlugs: string[] = []
    parent.depends_on.map((e: Element) => {
        if (!seenSlugs.includes(e.slug)) {
            depends_on.push(e)
            seenSlugs.push(e.slug)
        }
    })

    parent.children.map(c => {
        c.depends_on.map((e: Element) => {
            if (!seenSlugs.includes(e.slug)) {
                depends_on.push(e)
                seenSlugs.push(e.slug)
            }
        })
    })

    return depends_on
}

export const getAllUsedInForParent = (parent: Element) => {
    const used_in: Element[] = []
    const seenSlugs: string[] = []
    parent.used_in.map((e: Element) => {
        if (!seenSlugs.includes(e.slug)) {
            used_in.push(e)
            seenSlugs.push(e.slug)
        }
    })

    parent.children.map(c => {
        c.used_in.map((e: Element) => {
            if (!seenSlugs.includes(e.slug)) {
                used_in.push(e)
                seenSlugs.push(e.slug)
            }
        })
    })

    return used_in
}
