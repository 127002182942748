import { Pane, majorScale, Text } from 'evergreen-ui'
import { BOOKMAP } from '../../constants'

interface FilterByPartProps {
    onChangeBook: (s: string) => void
    selectedBook: string
}

export default ({ onChangeBook, selectedBook }: FilterByPartProps) => {
    return (
        <Pane>
            <Text marginRight={majorScale(1)}><b>Filter by Part:</b></Text>
            <select
                style={{ "margin": 0 }}
                value={selectedBook}
                onChange={(e) => onChangeBook(e.target.value)}
            >
                {Object.keys(BOOKMAP).map(k => (
                    <option key={k} value={k}>{BOOKMAP[k]}</option>
                ))}
            </select>
        </Pane>
    )
}
