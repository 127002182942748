import { useEffect, useState } from 'react'
import { Pane, Strong, Spinner, majorScale, Button, Heading, Text } from 'evergreen-ui'
import Graph from 'graphology'
import { fetchMapData, fetchSourcesForNode, fetchElementBySlug, Element, postGPTQuery } from '../api'
import { bidirectional } from 'graphology-shortest-path';
import MapListItem from '../components/MapPage/MapListItem'

export default () => {
    const [graph, setGraph] = useState<Graph>()
    const [nodes, setNodes] = useState<string[]>([])
    const [source, setSource] = useState("")
    const [destination, setDestination] = useState("")
    const [destinationCandidates, setDestinationCandidates] = useState<string[]>([])
    const [shortestPath, setShortestPath] = useState<Element[]>([])
    const [gptResult, setGptResult] = useState("")
    const [gptLoading, setGptLoading] = useState(false)
    const [noResults, setNoResults] = useState(false)

    useEffect(() => {
        if (shortestPath.length === 0) { return }
        const f = async () => {
            setGptLoading(true)
            const result = await postGPTQuery(shortestPath.map(e => e.slug))
            setGptResult(result.response)
            setGptLoading(false)
        }
        f()
    }, [shortestPath])

    useEffect(() => {
        const f = async () => {
            const d = await fetchMapData()
            //@ts-ignore
            setNodes(d.nodes.map(g => g.key))
            const g = new Graph();
            //@ts-ignore
            g.import(d)
            setGraph(g)
        }
        f()
    }, [])

    const handleCalculate = () => {
        if (!graph) {
            return
        }

        const path = bidirectional(graph, source, destination)
        if (path) {
            setNoResults(false)
            const f = async () => {
                const nodes = await Promise.all(path.map(p => fetchElementBySlug(p)))
                setShortestPath(nodes)
            }
            f()
        } else {
            setShortestPath([])
            setNoResults(true)
        }
    }


    useEffect(() => {
        if (source === "") return
        const f = async () => {
            const data = await fetchSourcesForNode(source)
            setDestinationCandidates(data.nodes)
        }
        f()
    }, [source])

    if (!graph) {
        return (
            <Pane>
                <Spinner />
            </Pane>
        )
    }

    return (
        <Pane>
            <Pane width={400} background="white" border margin="auto" marginTop={majorScale(2)} padding={majorScale(2)}>
                <Pane>
                    <Heading size={600}>Explain the relationship between...</Heading>
                </Pane>
                <Pane marginBottom={majorScale(1)}>
                    <Pane>
                        <Strong>Source:</Strong>
                        <Pane marginLeft={majorScale(1)}>
                            <select value={source} onChange={(item) => { setSource(item.target.value) }}>
                                <option value={""}>----</option>
                                {nodes.map(n => <option value={n}>{n}</option>)}
                            </select>
                        </Pane>
                    </Pane>
                    <Pane>
                        <Strong>Destination:</Strong>
                        <Pane marginLeft={majorScale(1)}>
                            <select value={destination} disabled={source == ""} onChange={(item) => { setDestination(item.target.value) }}>
                                <option value={""}>----</option>
                                {destinationCandidates.map(n => <option value={n}>{n}</option>)}
                            </select>
                        </Pane>
                    </Pane>
                </Pane>
                <Pane>
                    <Button disabled={source == "" || destination == ""} onClick={handleCalculate}>Calculate Shortest Path</Button>
                </Pane>
            </Pane>

            <Pane>
                <Heading>GPT Result</Heading>
                {gptLoading && <Spinner />}
                <Text>{gptResult}</Text>
            </Pane>
            <Pane>
                {noResults && <p>No results</p>}
                {shortestPath.map((el) => <MapListItem element={el} />)}
            </Pane>

        </Pane>
    )
}
