import { Element } from '../../api'
import { Pane, Text, Heading, majorScale } from 'evergreen-ui'
import { useNavigate } from 'react-router-dom'
import './ListElement.css'


interface ListElementProps {
    element: Element
}

export default ({ element }: ListElementProps) => {
    const navigate = useNavigate()


    return (
        <Pane
            hoverElevation={0}
            className="clickable list-element"
            onClick={() => navigate(`/element/${element.slug}`)}
        >
            <Pane minWidth={70} textAlign="center">
                <Heading size={700}>{element.slug}</Heading>
            </Pane>
            <Pane flex={1} paddingLeft={majorScale(1)} borderLeft>
                <Text>{element.english}</Text>
            </Pane>
        </Pane >
    )
}
