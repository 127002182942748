import { Element } from '../api'
import { SideSheet, majorScale, Position, Pane, Heading, Paragraph, Button, ManualIcon } from 'evergreen-ui'
import { useNavigate } from 'react-router-dom'


interface ElementSheetProps {
    element: Element | null
    onCloseComplete: () => void
    isShown: boolean
}

const ElementSheet = ({ element, isShown, onCloseComplete }: ElementSheetProps) => {

    const navigate = useNavigate()

    return (
        <SideSheet containerProps={{ "maxHeight": "300px" }} position={Position.BOTTOM} isShown={isShown} onCloseComplete={onCloseComplete}>
            <Pane>
                {element && (
                    <Pane padding={majorScale(4)}>
                        <Pane display="flex" alignItems="center" marginBottom={majorScale(2)}>
                            <Heading size={900}>{element.slug}</Heading>
                            <Button marginLeft={majorScale(2)} size={"small"} iconBefore={ManualIcon} onClick={() => navigate(`/element/${element.slug}`)}>Go to Element</Button>
                        </Pane>
                        <Paragraph size={500}>{element.english}</Paragraph>
                    </Pane>
                )}
            </Pane>
        </SideSheet >
    )
}

export default ElementSheet
