export const BOOK_ONE = "Book I"
export const BOOK_TWO = "Book II"
export const BOOK_THREE = "Book III"
export const BOOK_DEFINITION_AFFECTS = "Definition of the Affects"
export const BOOK_FOUR = "Book IV"
export const BOOK_FIVE = "Book V"
export const BOOKS = [BOOK_ONE, BOOK_TWO, BOOK_THREE, BOOK_DEFINITION_AFFECTS, BOOK_FOUR, BOOK_FIVE]

export const BOOKMAP: { [key: string]: string } = {
    "1": BOOK_ONE,
    "2": BOOK_TWO,
    "3": BOOK_THREE,
    "3DA": BOOK_DEFINITION_AFFECTS,
    "4": BOOK_FOUR,
    "5": BOOK_FIVE
}

export const TYPE_PROPOSITION = "Proposition"
export const TYPE_DEFINITION = "Definition"
export const TYPE_AXIOM = "Axiom"
export const TYPE_COROLLARY = "Corollary"
export const TYPE_SCHOLIUM = "Scholium"
export const TYPE_LEMMA = "Lemma"
export const TYPE_POSTULATE = "Postulate"
export const TYPE_DEMONSTRATION = "Demonstration"
