import ElementToken from './ElementToken'
import { Paragraph } from 'evergreen-ui'

interface TokenizedParagraphProps {
    text: string
    tokens?: Map<string, string>
}

interface TokenPart {
    index: number
    length: number
    value: string
}

const TokenizedParagraph = ({ tokens = new Map(), text }: TokenizedParagraphProps) => {
    const parts: TokenPart[] = []
    tokens.forEach((v, k) => {
        let index = text.indexOf(k)
        if (index === -1) return

        parts.push({
            index,
            length: k.length,
            value: v
        })
    })

    parts.sort((a, b) => a.index > b.index ? 1 : -1)

    const result: React.ReactNode[] = []
    let curIndex = 0
    parts.map(p => {
        result.push(<span>{text.substring(curIndex, p.index)}</span>)
        result.push(<ElementToken slug={p.value} value={text.substring(p.index, p.index + p.length)} />)
        curIndex = p.index + p.length
    })
    result.push(<span>{text.substring(curIndex)}</span>)

    return (
        <Paragraph size={400}>{result}</Paragraph>
    )
}

export default TokenizedParagraph
