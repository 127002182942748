import { useState } from 'react'
import { Pane, majorScale, IconButton, SearchTextIcon, Heading } from 'evergreen-ui'
import { Element } from '../api'
import ComparisonDialog from './ComparisonDialog'
import TokenizedParagraph from './TokenizedParagraph'
import tokenMap from '../tokenmap.json'

interface ElementDetailsProps {
    element: Element
}

const ElementDetail = ({ element }: ElementDetailsProps) => {
    const [isComparisonDialogShown, setIsComparisonDialogShown] = useState(false)

    //@ts-ignore
    const tm = new Map<string, string>(tokenMap[element.slug])

    const onOpenDialog = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault()
        setIsComparisonDialogShown(true)
    }

    return (
        <Pane
            className="clickable"
            border
            marginBottom={majorScale(1)}
            marginTop={majorScale(1)}
            padding={majorScale(2)}
            onClick={onOpenDialog}
        >
            <ComparisonDialog
                element={element}
                isShown={isComparisonDialogShown}
                onClose={() => setIsComparisonDialogShown(false)}
            />

            <Pane display="flex" alignItems="center">
                <Pane width={150} display="flex" alignItems="center" justifyContent="center">
                    <Heading>
                        {element.type} <br />
                    </Heading>
                </Pane>
                <Pane flex={1} borderLeft padding={majorScale(1)} background="#EEEEEE99">
                    <TokenizedParagraph text={element.english} tokens={tm} />
                </Pane>
            </Pane>
        </Pane >
    )
}

export default ElementDetail
