import { useState, useEffect, useRef } from 'react';
import { Element, fetchElements } from '../api';
import { Pane, majorScale, Spinner, Paragraph } from 'evergreen-ui'
import { getRandomLoadingMessage } from "../helpers"
import { ViewportList } from 'react-viewport-list';
import ListElement from '../components/TextPage/ListElement'
import FilterByPart from '../components/TextPage/FilterByPart'

function Home() {
    const [elements, setElements] = useState<Element[]>([])
    const [book, setBook] = useState("1")
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        const f = async () => {
            setIsLoading(true)
            const elements = await fetchElements()

            console.log(elements)
            setElements(elements.filter((e) => e.parent === null))
            setIsLoading(false)
        }
        f()
    }, [])

    const filteredElements = elements.filter(e => e.book === book)
    const ref = useRef<HTMLDivElement | null>(
        null,
    );

    if (isLoading) {
        const loadingMessage = getRandomLoadingMessage()
        return (
            <Pane padding={majorScale(2)} border textAlign="center" width={300} margin="auto" marginTop={majorScale(2)} background="white">
                <Paragraph>{loadingMessage}</Paragraph>
                <Spinner size={20} margin="auto" />
            </Pane >
        )
    }

    return (
        <Pane>
            <Pane textAlign="center" padding={majorScale(2)}>
                <FilterByPart selectedBook={book} onChangeBook={setBook} />
            </Pane>
            <Pane ref={ref} className="scroll-container">
                <ViewportList
                    viewportRef={ref}
                    items={filteredElements}
                >
                    {(e) => <ListElement element={e} />}
                </ViewportList>
            </Pane>
        </Pane >
    );
}

export default Home;
