import { Dialog, Pane, Paragraph, majorScale, Heading, Tooltip, InfoSignIcon } from 'evergreen-ui'
import { Element } from '../api'


interface ComparisonDialogProps {
    element: Element
    isShown: boolean
    onClose: () => void
}

const ComparisonDialog = ({ isShown, onClose, element }: ComparisonDialogProps) => {
    const handleDoubleClickLatin: React.MouseEventHandler<HTMLParagraphElement> = (e) => {
        const selection = window.getSelection()
        if (selection) {
            window.open(`https://www.perseus.tufts.edu/hopper/morph?la=la&l=${selection.toString()}`, "_blank")
        }
    }

    return (
        <Dialog isShown={isShown} onCloseComplete={() => onClose()} width="75%" hasFooter={false} hasHeader={false}>
            <Pane display="flex" border margin={majorScale(4)}>
                <Pane flex={1} borderRight>
                    <Pane padding={majorScale(1)} borderBottom backgroundColor="#FAFAFA">
                        <Heading>English</Heading>
                    </Pane>
                    <Pane padding={majorScale(1)}>
                        <Paragraph>{element.english}</Paragraph>
                    </Pane>
                </Pane>
                <Pane flex={1} borderRight>
                    <Pane padding={majorScale(1)} borderBottom backgroundColor="#FAFAFA">
                        <Pane display="flex" alignItems="center">
                            <Heading>Latin</Heading>
                            <Tooltip content="Double click any latin word to view its Perseus page">
                                <InfoSignIcon marginLeft={majorScale(1)} size={14} />
                            </Tooltip>
                        </Pane>
                    </Pane>
                    <Pane padding={majorScale(1)}>
                        <Paragraph onDoubleClick={handleDoubleClickLatin}>{element.latin}</Paragraph>
                    </Pane>
                </Pane>

            </Pane>
        </Dialog>
    )
}

export default ComparisonDialog
