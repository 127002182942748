import { useEffect, useState } from 'react'
import { Pane, Spinner, MaximizeIcon, Button, majorScale, Heading } from "evergreen-ui"
import CytoscapeComponent from 'react-cytoscapejs'
import dagre from 'cytoscape-dagre';
import Cytoscape from 'cytoscape'
import { useParams } from "react-router-dom"
import { useLocation } from 'react-router-dom'
import { fetchElementBySlug, Element } from "../api"
import ElementCard from '../components/ElementCard'
import ElementSheet from '../components/ElementSheet'
import TreeViewDialog from '../components/TreeViewModal'
import { elementStylesheet } from '../graphoptions'

Cytoscape.use(dagre)

export default () => {
    const { slug } = useParams()
    const [element, setElement] = useState<Element | null>()
    const [isLoading, setIsLoading] = useState(true)
    const [isTreeViewDialogShown, setIsTreeViewDialogShown] = useState(false)
    const [selectedElement, setSelectedElement] = useState<Element | null>(null)

    const location = useLocation()

    const handleToggleTreeViewDialog = () => {
        setIsTreeViewDialogShown(!isTreeViewDialogShown)
    }

    const setupGraph = (cy: cytoscape.Core) => {
        cy.on('tap', 'node', async (e) => {
            const se = await fetchElementBySlug(e.target.id())
            setSelectedElement(se)
        });
    }

    // Reset Selected Element on navigating to new element page
    useEffect(() => {
        setSelectedElement(null)
    }, [location.pathname])

    useEffect(() => {
        if (element && slug === element.slug) {
            return
        }
        const f = async () => {
            if (!isLoading) setIsLoading(true)
            try {
                const e = await fetchElementBySlug(slug!)
                setElement(e)
            } finally {
                setIsLoading(false)
            }
        }
        f()
    }, [slug, isLoading])

    if (isLoading) {
        return (
            <Pane width="100%" height="100%">
                <Spinner margin="auto" />
            </Pane>
        )
    }

    if (!element) {
        return <Pane>Something went wrong</Pane>
    }

    const nodes = [
        { data: { id: element.slug, label: element.slug, book_number: element.book } },
        ...element.depends_on.map(e => ({ data: { id: e.slug, label: e.slug, book_number: e.book } })),
        ...element.used_in.map(e => ({ data: { id: e.slug, label: e.slug, book_number: e.book } })),
    ];

    const edges = [
        ...element.depends_on.filter(e => e.slug !== element.slug).map(e => ({ data: { id: `${e.slug}${element.slug}`, source: e.slug, target: element.slug } })),
        ...element.used_in.filter(e => e.slug !== element.slug).map(e => ({ data: { id: `${element.slug}${e.slug}`, source: element.slug, target: e.slug } })),
    ]

    return (
        <Pane padding={majorScale(2)}>
            <ElementSheet
                element={selectedElement}
                onCloseComplete={() => setSelectedElement(null)}
                isShown={selectedElement !== null}
            />
            <Pane>
                <Pane>
                    <Heading textAlign="center" size={900}>{element.slug}</Heading>
                    <ElementCard element={element} />
                </Pane>
                <Pane border background="white">
                    <CytoscapeComponent
                        cy={setupGraph}
                        stylesheet={elementStylesheet}
                        elements={CytoscapeComponent.normalizeElements({ nodes, edges })}
                        layout={{ name: "dagre" }}
                        style={{ width: '100%', height: 200, background: "white" }}
                        minZoom={0.1}
                        maxZoom={1}
                    />
                    <Pane textAlign="right" marginTop={majorScale(-5)}>                        
                        <Button
                            size={"large"}
                            iconAfter={MaximizeIcon}
                            appearance="minimal"
                            onClick={handleToggleTreeViewDialog}
                        >
                            Open Explorer
                            </Button>
                    </Pane>
                </Pane>
                {element.children.map(c => <ElementCard element={c} />)}
            </Pane>
            <TreeViewDialog
                isShown={isTreeViewDialogShown}
                onClose={handleToggleTreeViewDialog}
                element={element}
            />


        </Pane >
    )
}
