import { Pane, majorScale, Paragraph } from 'evergreen-ui'
import { useNavigate } from 'react-router-dom'

interface PageTileProps {
    title: string
    link: string
    image: string
}

export default ({ title, link, image }: PageTileProps) => {
    const navigate = useNavigate()

    return (
        <Pane
            background="white"
            border
            margin={majorScale(1)}
            padding={majorScale(1)}
            className="clickable"
            onClick={() => { navigate(link) }}
        >
            <img alt={title} style={{ "marginBottom": 0 }} width={150} height={150} src={image} />
            <Paragraph>{title}</Paragraph>
        </Pane>
    )

}
